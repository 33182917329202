#footer #footer-top .klaviyo-form-PAPDgq div,
#footer #footer-top .klaviyo-form-PAPDgq span:not([role="alert"]),
#footer #footer-top .klaviyo-form-PAPDgq input {
    @apply text-white font-sans !important;
}
#footer #footer-top .klaviyo-form-PAPDgq .klaviyo-form {
    @apply px-0 !important;
}
#footer #footer-top .klaviyo-form-PAPDgq input {
    @apply w-full border-0 bg-transparent py-[18px] text-xl font-[300] outline-0 lg:py-[22px] px-0 lg:text-[28px]  !important;
}

#footer #footer-top .klaviyo-form-PAPDgq input::placeholder {
    @apply lg:text-[28px] font-[300] text-xl !important;
}

#footer #footer-top .klaviyo-form-PAPDgq [data-testid="form-component"] {
    @apply p-0 pointer-events-none !important;
}
