/* Subscribe Checkbox styles for visually.io A/B testing purposes */

.subscribe-unlock {
    position: relative;
    display: flex;
    margin-bottom: 2rem;
    border: 0.1rem solid #0064b7;
    border-radius: 0.2rem;
    width: 100%;
    padding: 0.4rem 1rem;
    background: linear-gradient(
      270deg,
      rgba(33, 121, 194, 0.2) 0.01%,
      rgba(141, 206, 249, 0.2) 51.05%,
      rgba(241, 220, 193, 0.2) 100%
    );
  }
  
  .subscribe-unlock__content {
    align-items: center;
    display: flex;
    width: 100%;
  }
  
  .subscribe-unlock__content p {
    font-weight: 400;
    color: #0064b7;
  }
  
  .subscribe-unlock__tooltip {
    position: absolute;
    top: -7.1rem;
    right: -1.2rem;
    display: block;
    border-radius: 0.2rem;
    width: 15rem;
    padding: 1rem;
    box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.2);
    background-color: #f2f7fb;
  }
  
  .subscribe-unlock__tooltip__inner {
    position: relative;
  }
  
  .subscribe-unlock__tooltip__inner::before {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: -2.3rem;
    right: -1.5rem;
    display: block;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 2rem;
    height: 2rem;
    background-color: #f2f7fb;
  }
  
  .subscribe-unlock__tooltip ul {
    position: relative;
    z-index: 1;
    padding-left: 0.5rem;
    list-style: disc;
  }
  
  .subscribe-unlock__tooltip li {
    font-size: .75rem;
    color: #343B49;
  }
  
  .subscribe-unlock__content [type="checkbox"] {
    margin-right: 0.5rem;
  }
  
  .subscribe-unlock__icon {
    position: relative;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    width: 100%;
    cursor: pointer;
  }

  .subscribe-unlock__icon svg {
    width: .7rem;
    color: #0064B7;
  }

  .subscribe-unlock__icon.active svg {
    color: #8DCEF9;
  }
  
  .custom-checkbox {
    display: none;
  }
  
  .custom-checkbox-label {
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  
  .custom-checkbox-checkmark {
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: background 0.3s;
    margin-right: 0.5rem;
    border: 0.1rem solid #0064b7;
    border-radius: 0.1rem;
    width: 16px;
    height: 16px;
  }
  
  .custom-checkbox:checked + .custom-checkbox-checkmark {
    background: #0064b7;
  }
  
  .custom-checkbox:checked + .custom-checkbox-checkmark::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0.25rem;
    transform: rotate(45deg);
    border: solid white;
    border-width: 0 2px 2px 0;
    width: 6px;
    height: 10px;
  }
  