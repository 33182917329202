@import 'swiper/css/bundle';
@import './footer.css';
@import './phoneForm.css';
@import './SubscribeCheckbox.css';
@import './ScrollyVideoSection.css';
@import './ProductDetailsSection.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global variables. */
:root {
  /* Set sans-serif & mono fonts */
  --sans-font: -apple-system, BlinkMacSystemFont, 'Avenir Next', Avenir,
    'Nimbus Sans L', Roboto, Noto, 'Segoe UI', Arial, Helvetica,
    'Helvetica Neue', sans-serif;
  --mono-font: Consolas, Menlo, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;

  /* Default colors; will be overridden by cms theme settings */
  --background: #ecf7ff;
  --text: #202635;
  --border: #ecf7ff;

  --primary: #202635; /* Deep Blue */
  --secondary: #0064b7; /* Clear Blue */
  --accent-1: #f1dcc1; /* Sand */
  --accent-2: #8dcef9; /* Light Blue */
  --accent-3: #c1effa; /* Light Cyan */
  --accent3Opacity20: rgb(193 239 250 / 20%);

  --success: #5abe8a;
  --error: #ed7c5c;

  --black: #111111;
  --off-black: #202635;
  --dark-gray: #484848;
  --medium-dark-gray: #707070;
  --medium-gray: #989898;
  --gray: #d9d9d9;
  --light-gray: #e8e8e8;
  --shadow-gray: #797d86;
  --off-white: #ecf7ff;
  --white: #ffffff;
  --clearBlue: #f2f7fb;
  --clearBlue40: #b3d1e9;
  --textLink: #3383c5;
  --bannerGradient1: rgba(33, 121, 194, 0.2);
  --bannerGradient2: rgba(141, 206, 249, 0.2);
  --bannerGradient3: rgba(241, 220, 193, 0.2);
  --bannerBg: linear-gradient(
    270deg,
    #2179c2 0.01%,
    #8dcef9 51.05%,
    #f1dcc1 100%
  );
  --bannerBgLight: linear-gradient(
    90deg,
    rgba(193, 239, 250, 0.2) 0%,
    rgba(141, 206, 249, 0.2) 100%
  );

  --primary-btn-bg-color: var(--primary);
  --primary-btn-border-color: var(--primary);
  --primary-btn-text-color: var(--white);
  --primary-btn-hover-bg-color: var(--secondary);
  --primary-btn-hover-border-color: var(--secondary);
  --primary-btn-hover-text-color: var(--white);

  --secondary-btn-bg-color: var(--secondary);
  --secondary-btn-border-color: var(--secondary);
  --secondary-btn-text-color: var(--white);
  --secondary-btn-hover-bg-color: var(--primary);
  --secondary-btn-hover-border-color: var(--primary);
  --secondary-btn-hover-text-color: var(--white);

  --inverse-light-btn-bg-color: transparent;
  --inverse-light-btn-border-color: var(--white);
  --inverse-light-btn-text-color: var(--white);
  --inverse-light-btn-hover-bg-color: var(--white);
  --inverse-light-btn-hover-border-color: var(--white);
  --inverse-light-btn-hover-text-color: var(--black);

  --inverse-dark-btn-bg-color: transparent;
  --inverse-dark-btn-border-color: var(--black);
  --inverse-dark-btn-text-color: var(--black);
  --inverse-dark-btn-hover-bg-color: var(--black);
  --inverse-dark-btn-hover-border-color: var(--black);
  --inverse-dark-btn-hover-text-color: var(--white);

  /* --swiper-pagination-bullet-size: "1px"; */

  /* Breakpoints */
  --xs: 480px;
  --sm: 640px;
  --md: 768px;
  --lg: 1024px;
  --xl: 1280px;
  --2xl: 1536px;

  /* Constants */
  --header-height: 83px;
  --promobar-height: 36px;
  --sidebar-width: 23.5rem;
  --product-image-aspect-ratio: 3 / 4;
  --content-max-width: 96rem;
}

.vimeo-wrapper {
  background: #eee;
  height: 100vh;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.vimeo-wrapper iframe {
  box-sizing: border-box;
  height: 56.25vw;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  width: 177.77777778vh;
}

@layer base {
  * {
    box-sizing: border-box;
  }

  html {
    @apply flex min-h-full w-full scroll-p-[200px] flex-col scroll-smooth font-sans;
  }

  body {
    @apply text-body m-0 flex min-h-[var(--viewport-height)] w-full scroll-p-[200px] flex-col bg-background text-text;
  }

  #__next {
    @apply flex min-h-[var(--viewport-height)] flex-col;
  }

  main {
    @apply flex-1;
  }

  h1 {
    @apply text-title-h1;
  }

  h2 {
    @apply text-title-h2;
  }

  h3 {
    @apply text-title-h3;
  }

  h4 {
    @apply text-title-h4;
  }

  h4 + div > p {
    @apply !mb-8;
  }

  h5 {
    @apply text-title-h5;
  }

  h6 {
    @apply text-title-h6;
  }

  p {
    @apply font-light;
  }

  p > img {
    @apply pt-8;
  }

  p > a {
    @apply font-[#3383C5] text-[#3383C5];
  }

  p + h6 {
    @apply !mb-8;
  }

  .markdown h6 {
    @apply text-[16px] font-[400] lg:text-[20px];
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  iframe {
    width: 100%;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer components {
  /* text specific */
  .text-title-h1 {
    @apply font-sans
      text-4xl
      font-bold
      md:text-5xl
      xl:text-6xl;
  }
  .text-title-h2 {
    @apply font-sans
      text-3xl
      font-bold
      md:text-4xl
      xl:text-5xl;
  }
  .text-title-h3 {
    @apply font-sans
      text-2xl
      font-bold
      md:text-3xl
      xl:text-4xl;
  }
  .text-title-h4 {
    @apply font-sans
      text-xl
      font-bold
      md:text-2xl
      xl:text-3xl;
  }
  .text-title-h5 {
    @apply font-sans
      text-base
      font-bold
      md:text-lg;
  }
  .text-title-h6 {
    @apply font-sans
      text-sm
      font-bold;
  }
  .text-body {
    @apply font-sans
      text-base
      font-normal;
  }
  .text-body-sm {
    @apply font-sans
      text-xs
      font-normal;
  }
  .text-body-lg {
    @apply font-sans
      text-lg
      font-normal;
  }
  .text-link {
    @apply text-body
      font-bold
      uppercase
      underline;
  }
  .text-link-sm {
    @apply text-link
      text-xs
      underline-offset-4;
  }
  .text-caption {
    @apply font-sans
      text-sm
      font-bold
      uppercase;
  }
  .text-label {
    @apply font-sans
      text-xs
      font-normal

      uppercase;
  }
  .text-label-sm {
    @apply font-sans
      text-2xs
      font-normal

      uppercase;
  }
  .text-nav {
    @apply font-sans
      text-[17px];
  }
  .text-superheading {
    @apply font-sans
      text-xs

      font-normal
      uppercase
      lg:text-sm;
  }
  /* button specific */
  .btn-text {
    @apply font-sans
      text-base
      font-bold
      uppercase;
  }
  .btn {
    @apply btn-text
      relative
      m-0
      inline-flex
      h-[3.125rem]
      cursor-pointer
      items-center
      justify-center
      overflow-hidden
      whitespace-nowrap
      rounded
      border
      border-solid
      px-5
      py-3
      text-center
      transition-colors
      disabled:cursor-not-allowed
      disabled:border-border
      disabled:bg-lightGray
      disabled:text-mediumGray
      disabled:hover:border-border
      disabled:hover:bg-lightGray
      disabled:hover:text-mediumGray
      [&>p]:truncate;
  }
  .btn-primary {
    @apply btn
      border-[var(--primary-btn-border-color)]
      bg-[var(--primary-btn-bg-color)]
      text-[var(--primary-btn-text-color)]
      md:hover:border-[var(--primary-btn-hover-border-color)]
      md:hover:bg-[var(--primary-btn-hover-bg-color)]
      md:hover:text-[var(--primary-btn-hover-text-color)];
  }
  .btn-secondary {
    @apply btn
      border-[var(--secondary-btn-border-color)]
      bg-[var(--secondary-btn-bg-color)]
      text-[var(--secondary-btn-text-color)]
      md:hover:border-[var(--secondary-btn-hover-border-color)]
      md:hover:bg-[var(--secondary-btn-hover-bg-color)]
      md:hover:text-[var(--secondary-btn-hover-text-color)];
  }
  .btn-inverse-light {
    @apply btn
      border-[var(--inverse-light-btn-border-color)]
      bg-[var(--inverse-light-btn-bg-color)]
      text-[var(--inverse-light-btn-text-color)]
      md:hover:border-[var(--inverse-light-btn-hover-border-color)]
      md:hover:bg-[var(--inverse-light-btn-hover-bg-color)]
      md:hover:text-[var(--inverse-light-btn-hover-text-color)];
  }
  .btn-inverse-dark {
    @apply btn
      border-[var(--inverse-dark-btn-border-color)]
      bg-[var(--inverse-dark-btn-bg-color)]
      text-[var(--inverse-dark-btn-text-color)]
      md:hover:border-[var(--inverse-dark-btn-hover-border-color)]
      md:hover:bg-[var(--inverse-dark-btn-hover-bg-color)]
      md:hover:text-[var(--inverse-dark-btn-hover-text-color)];
  }
  /* underline specific */
  .hover-text-underline {
    @apply relative
      inline
      bg-[linear-gradient(currentColor,currentColor)]
      bg-[length:0%_1px]
      bg-left-bottom
      bg-no-repeat
      transition-[background-size]
      duration-200
      focus:bg-[length:100%_1px]
      md:hover:bg-[length:100%_1px];
  }
  .text-underline {
    @apply relative
      inline
      bg-[linear-gradient(currentColor,currentColor)]
      bg-[length:100%_1px]
      bg-right-bottom
      bg-no-repeat
      transition-[background-size]
      duration-200
      focus:bg-[length:0%_1px]
      md:hover:bg-[length:0%_1px];
  }
  .text-main-underline {
    @apply relative
      inline
      bg-[linear-gradient(currentColor,currentColor)]
      bg-[length:100%_2px]
      bg-right-bottom
      bg-no-repeat
      pb-px
      transition-[background-size]
      duration-200
      focus:bg-[length:0%_2px]
      md:hover:bg-[length:0%_2px];
  }
  /* inputs specific */
  .input-text {
    @apply h-12
      w-full
      rounded-full
      border
      border-border
      px-5
      py-2.5
      text-base;
  }
  .input-label {
    @apply block
      pb-1
      pl-5
      font-sans
      text-sm
      font-bold;
  }
  /* swiper specific */
  .swiper-wrapper-center .swiper-wrapper {
    @apply flex
      items-center;
  }
  .swiper-offset-gradient-270-left {
    background: linear-gradient(
      270deg,
      var(--background) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    @apply pointer-events-none absolute bottom-0 left-0 top-0 z-10 h-full w-[5.9375rem] -rotate-180 opacity-[60] lg:w-[8.125rem];
  }
  .swiper-offset-gradient-270-right {
    background: linear-gradient(
      270deg,
      var(--background) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    @apply pointer-events-none absolute bottom-0 right-0 top-0 z-10 h-full w-[5.9375rem] opacity-[60] lg:w-[8.125rem];
  }
  .active-bullet-black .swiper-pagination-bullet-active {
    @apply bg-black;
  }

  .swiper-progress .swiper-pagination-bullet {
    @apply h-[1px] lg:!m-0;
  }
}

@layer utilities {
  /* hide scrollbar */
  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-hide > *::-webkit-scrollbar {
    display: none;
  }

  .custom-scroll > *::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  .custom-scroll > *::-webkit-scrollbar-track {
    background: #f2f7fb;
  }

  .custom-scroll > *::-webkit-scrollbar-thumb {
    background: #202635;
  }

  /* horizontal padding */
  .px-contained {
    @apply px-4
      md:px-8
      xl:px-12;
  }
  /* horizontal padding on mobile only */
  .max-md-px-contained {
    @apply max-md:px-4;
  }
  /* horizontal padding on desktop only */
  .md-px-contained {
    @apply px-0
      md:px-8
      xl:px-12;
  }
  /* horizontal padding on larger desktop only */
  .lg-px-contained {
    @apply px-0
      md:px-0
      lg:px-8
      xl:px-12;
  }
  /* vertical padding */
  .py-contained {
    @apply py-8
      md:py-10
      xl:py-12;
  }
}

.legal__markdown h3 {
  @apply font-light tracking-[-.01em];
}

@media only screen and (min-width: 1024px) {
  .legal-menu:before {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(100% + 70px);
    background: #dde2e7;
    right: 30%;
    top: -70px;
  }

  .legal-menu li:hover,
  .legal-menu li.active {
    color: #3383c5;
  }

  .legal-menu li.active:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background: #3383c5;
    left: -18px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
  }
}

.portal-form .input-label {
  @apply pb-1 pl-0 text-[16px] font-[300];
}
.portal-form .input-text {
  @apply h-14 rounded-none text-sm font-light outline-none hover:border-[#333333] focus:border-[#333333];
}

.portal-form .select-trigger {
  @apply h-14 rounded-none bg-white text-sm font-light outline-none hover:border-[#333333] focus:border-[#333333];
}
.portal-form .select-options {
  @apply rounded-none bg-white text-sm font-light outline-none hover:border-[#333333] focus:border-[#333333];
}

@keyframes newsTicker {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-news-ticker-1 {
  animation: newsTicker 20s linear infinite;
  height: 25px;
  flex-shrink: 0;
  white-space: nowrap;
}

.oke-sr {
  --oke-stars-foregroundColor: #2179c2;
}

.newseletter .klaviyo-form-PAPDgq div,
.newseletter.klaviyo-form-PAPDgq span:not([role='alert']),
.newseletter .klaviyo-form-PAPDgq input {
  @apply font-sans text-[#202635] !important;
}
.newseletter .klaviyo-form-PAPDgq .klaviyo-form {
  @apply px-0 !important;
}
.newseletter .klaviyo-form-PAPDgq input {
  @apply w-full border-l-0 border-r-0 border-t-0 bg-transparent px-0 py-[18px] pb-2 text-xl font-[300] outline-0 lg:py-[22px] lg:text-[28px]  !important;
}

.newseletter .klaviyo-form-PAPDgq input::placeholder {
  @apply text-xl font-[300] text-[#202635] lg:text-[28px] !important;
}

.newseletter .klaviyo-form-PAPDgq [data-testid='form-component'] {
  @apply pointer-events-none p-0 !important;
}

.newseletter .klaviyo-form-PAPDgq button[type='button'] {
  @apply pointer-events-none invisible block h-8 w-8 items-center justify-center px-0 after:pointer-events-auto after:ml-auto after:block after:h-[18px] after:w-[18px] after:-translate-y-8 after:cursor-pointer after:bg-arrow-right after:bg-contain after:bg-center after:bg-no-repeat after:text-white after:content-[''] after:lg:w-[24px] !important;
}

.newseletter .klaviyo-form-PAPDgq button[type='button']:after {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none"><path d="M0.000190735 10.0006C0.000190735 10.2658 0.105547 10.5201 0.293083 10.7077C0.480619 10.8952 0.734974 11.0006 1.00019 11.0006H20.5864L13.2927 18.2931C13.1998 18.386 13.1261 18.4963 13.0758 18.6177C13.0255 18.7391 12.9996 18.8692 12.9996 19.0006C12.9996 19.132 13.0255 19.2621 13.0758 19.3835C13.1261 19.5048 13.1998 19.6151 13.2927 19.7081C13.3856 19.801 13.4959 19.8747 13.6173 19.9249C13.7387 19.9752 13.8688 20.0011 14.0002 20.0011C14.1316 20.0011 14.2617 19.9752 14.3831 19.9249C14.5045 19.8747 14.6148 19.801 14.7077 19.7081L23.7077 10.7081C23.8007 10.6152 23.8744 10.5049 23.9247 10.3835C23.9751 10.2621 24.001 10.132 24.001 10.0006C24.001 9.86914 23.9751 9.73901 23.9247 9.61762C23.8744 9.49622 23.8007 9.38593 23.7077 9.29306L14.7077 0.293056C14.5201 0.105415 14.2656 -1.97712e-09 14.0002 0C13.7348 1.97712e-09 13.4803 0.105415 13.2927 0.293056C13.105 0.480697 12.9996 0.735192 12.9996 1.00056C12.9996 1.26592 13.105 1.52042 13.2927 1.70806L20.5864 9.00056H1.00019C0.734974 9.00056 0.480619 9.10591 0.293083 9.29345C0.105547 9.48099 0.000190735 9.73534 0.000190735 10.0006Z" fill="%23202635"/></svg>') !important;
}

.newseletter .klaviyo-form-PAPDgq p,
.newseletter .klaviyo-form-PAPDgq p > span {
  @apply !text-left !font-sans !font-light;
}

.oke-w-review {
  @apply border border-[#DDE2E7] !bg-[#F2F7FB];
}

.oke-w-review-side {
  @apply !mr-0 !border-none !bg-transparent !p-6 !pl-8;
}

.oke-w-review-main {
  @apply !p-6;
}

.oke-w-main,
.oke-w-header {
  --oke-highlightColor: #2179c2;
  --oke-stars-foregroundColor: #2179c2;
  --oke-widget-spaceAbove: 20px;
  --oke-widget-spaceBelow: 20px;
  --oke-starRating-spaceAbove: 0px;
  --oke-starRating-spaceBelow: 0px;
  --oke-button-backgroundColor: #f2f7fb;
  --oke-button-backgroundColorHover: #dde2e7;
  --oke-button-backgroundColorActive: #343b49;
  --oke-button-textColor: #272d45;
  --oke-button-textColorHover: #272d45;
  --oke-button-textColorActive: #ffffff;
  --oke-button-borderColor: #dbdde4;
  --oke-button-borderColorHover: #dbdde4;
  --oke-button-borderColorActive: #343b49;
  --oke-button-borderRadius: 4px;
  --oke-button-borderWidth: 1px;
  --oke-button-fontWeight: 700;
  --oke-button-fontSize: var(--oke-text-regular, 14px);
  --oke-button-fontFamily: inherit;
  --oke-border-color: #e5e5eb;
  --oke-border-width: 1px;
  --oke-text-primaryColor: #202635;
  --oke-text-secondaryColor: #343b49;
  --oke-text-small: 12px;
  --oke-text-regular: 14px;
  --oke-text-large: 20px;
  --oke-text-fontFamily: inherit;
  --oke-avatar-size: 48px;
  --oke-avatar-backgroundColor: #e5e5eb;
  --oke-avatar-placeholderTextColor: #202635;
  --oke-shadingColor: #f2f7fb;
  --oke-productImageSize: 48px;
  --oke-attributeBar-shadingColor: #9a9db1;
  --oke-attributeBar-borderColor: #9a9db1;
  --oke-attributeBar-backgroundColor: #d3d4dd;
  --oke-attributeBar-markerColor: #00caaa;
  --oke-filter-backgroundColor: #ffffff;
  --oke-filter-backgroundColorActive: #343b49;
  --oke-filter-borderColor: #dbdde4;
  --oke-filter-borderColorActive: #343b49;
  --oke-filter-textColor: #202635;
  --oke-filter-textColorActive: #ffffff;
  --oke-filter-borderRadius: 100px;
  --oke-filter-searchHighlightColor: #b2f9e9;
  --oke-mediaGrid-chevronColor: #343b49;
  --oke-stars-borderWidth: 0;
}

.oke-w-header {
  @apply border border-[#DDE2E7] !bg-[#F2F7FB] !p-6;
}

.oke-w-breakdownModule-distribution-bar {
  @apply !h-4 !rounded-none !bg-[#CCE0F1];
}
.oke-w-breakdownModule-distribution-bar-fill {
  @apply !h-4 !rounded-none !bg-[#2179C2];
}

.oke-poweredBy {
  @apply !mt-2 !opacity-20 hover:!opacity-30;
}

.oke-w-reviewer-avatar {
  @apply !hidden;
}

.oke-w-reviewer-name {
  @apply !text-xl;
}

.oke-w-reviewer-verified {
  @apply mt-3 flex flex-row-reverse gap-2 !font-normal;
}

.oke-w-reviewer-verified-icon::before {
  @apply !ml-0 text-base;
}

.oke-reviewContent-title {
  @apply !text-2xl !font-medium;
}

.swiper-slide-next {
  bottom: 0;
}

.image-actions-carousel-mobile::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 1px;
  background: #dde2e7;
  height: 1px;
}

.image-actions-carousel-mobile::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #202635;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
