.scrolly-video__mob .swiper-slide-active {
    margin-top: -1rem;
}

.scrolly-video__desk__subtitle p {
    @apply !text-[1rem];
}
  

.scrolly-video__mob__subtitle p {
    @apply !text-[0.875rem];
}
  